.App {
  text-align: left;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

ul {
  width: 1100px;
  display: flex;
  flex-wrap: wrap;
  list-style-type:none; 
  margin: 0;
  padding: 0;
}

li {
  list-style-type:none; 
  display:flex;
  padding: 10px;
}

:root{

  --amplify-primary-color: #004d80;
  --amplify-primary-tint: #0000FF; 
  --amplify-primary-shade: #005780;

  }

  amplify-authenticator {
    --container-align: top;
  }

body{
  background: radial-gradient(circle,rgba(60,98,145,0.48) 3%,#3a79b7 103%);
}

@import 'styles/_home.scss'; 
@import '~semantic-ui-css/semantic.css';
